/* eslint-disable import/exports-last */
interface FlightTimeLineDataModel {
  departing_flight:
  {
    airline: { iata: string, id: string, name: string }
    aircraft: string
    arrival: string
    arrival_point: string
    arrival_time: string
    departure: string
    departure_point: string
    departure_time: string
    distance: number
    facilities: { icon: string, label: string }[]
    flight_id: string
    flight_number: string
    flight_time_hours: number
  }[]
  departure_route: string
  inventory_type: string
  key: string
  pax: number
  pnr: string
  price: number
  product_id: string
  return_route: string
  returning_flight:
  {
    airline: { iata: string, id: string, name: string }
    aircraft: string
    arrival: string
    arrival_point: string
    arrival_time: string
    departure: string
    departure_point: string
    departure_time: string
    distance: number
    facilities: { icon: string, label: string }[]
    flight_id: string
    flight_number: string
    flight_time_hours: number
  }[]
}

interface TaCDataMOdel {
  booking_fee: string[]
  down_payment: string[]
  full_payment: string[]
  full_payment_approval: string[]
  insert_passenger: string[]
  issued_ticket: string[]
  materialisasi: string[]
  order_reduction: string[]
  others: string[]
  ticket_terms: string[]
  time_limit: string[]
}

export interface SummaryTimeLimitDataModel {
  [key: string]: unknown
  description: string
  payment_timelimit: string
}

interface HasilPencarianModel {
  flightTimeLine: {
    alternativeData: FlightTimeLineDataModel[]
    alternativeTotal: number
    data: FlightTimeLineDataModel[]
    total: number
  }
  summaryTimeLimit: SummaryTimeLimitDataModel[]
}

enum HasilPencarianActionType {
  GetFlightTimeLineList = '⌘➝App➝Pencarian➝GetFlightTimeLineList',
  GetSummaryTimeLimit = '⌘➝App➝Pencarian➝GetSummaryTimeLimit'
}

export type HasilPencarianAction = {
  data: {
    alternativeData: FlightTimeLineDataModel[]
    alternativeTotal: number
    data: FlightTimeLineDataModel[]
    total: number
  }
  type: HasilPencarianActionType.GetFlightTimeLineList
} | {
  data: SummaryTimeLimitDataModel[]
  type: HasilPencarianActionType.GetSummaryTimeLimit
};

export { HasilPencarianActionType };
export type { FlightTimeLineDataModel, HasilPencarianModel, TaCDataMOdel };
